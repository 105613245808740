import React from 'react'

const AddUserIcon = ({ fill }) => {

    return (
        <div>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 328.5 328.5" fill={fill ? fill : '#000'}>
                <polygon points="96.333,150.918 96.333,135.918 55.667,135.918 55.667,95.251 40.667,95.251 40.667,135.918 0,135.918 0,150.918 
                    40.667,150.918 40.667,191.583 55.667,191.583 55.667,150.918"/>
                <path d="M259.383,185.941H145.858c-38.111,0-69.117,31.006-69.117,69.117v39.928H328.5v-39.928
                    C328.5,216.948,297.494,185.941,259.383,185.941z M313.5,279.987H91.741v-24.928c0-29.84,24.276-54.117,54.117-54.117h113.524
                    c29.84,0,54.117,24.277,54.117,54.117L313.5,279.987L313.5,279.987z"/>
                <path d="M202.621,178.84c40.066,0,72.662-32.597,72.662-72.663s-32.596-72.663-72.662-72.663s-72.663,32.596-72.663,72.663
                    S162.555,178.84,202.621,178.84z M202.621,48.515c31.795,0,57.662,25.867,57.662,57.663s-25.867,57.663-57.662,57.663
                    c-31.796,0-57.663-25.868-57.663-57.663S170.825,48.515,202.621,48.515z"/>
            </svg>
        </div>
    )
}

export default AddUserIcon