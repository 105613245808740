import React, { useEffect, useState } from 'react'
import './EditCustomer.scss'
import Modal from '../Modal/Modal'
import Input from '../Input/Input'
import Button from '../Button/Button'
import { useTranslation } from 'react-i18next'
import { UPDATE_CUSTOMER } from '../../graphql/mutations'
import { useAuth } from '../../hooks/useAuth'
import { useMutation } from '@apollo/client'

const EditCustomer = ({ show, close, title, setField, customerItem, onSuccess, removeText }) => {

  const initialState = {
    name: '',
    phone: '',
    email: '',
    address: '',
  }
  const { t } = useTranslation()
  const { user } = useAuth()
  const [formErrors, setFormErrors] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)

  const [updateCustomerMutation, { error: updateCustomerError, reset: resetUpdateCustomer }] = useMutation(UPDATE_CUSTOMER, {
    errorPolicy: 'all',
  })

  useEffect(() => {
    return () => {
      resetUpdateCustomer()
      setFormErrors(initialState)
      setLoading(false)
      setSuccess(null)
    }
  }, [])

  useEffect(() => {
    if (!show) {
      resetUpdateCustomer()
      setFormErrors(initialState)
      setLoading(false)
      setSuccess(null)
    }
  }, [show])

  if (!customerItem) return (<></>)

  const update = async () => {
    if (hasErrors()) return
    setLoading(true)

    try {
      const variables = {
        ...customerItem,
      }

      const res = await updateCustomerMutation({
        variables,
      })

      if (res.data?.updateCustomer) {
        setSuccess(t('Customer successfully updated'))
        if (onSuccess) onSuccess()
      }
    } catch (err) {
      console.log('error updating customer', err)
    } finally {
      setLoading(false)
    }
  }

  const removeCustomer = async () => {
    setLoading(true)
    try {
      const variables = {
        ...customerItem,
        remove: true,
      }

      const res = await updateCustomerMutation({
        variables,
      })

      if (res.data?.updateCustomer) {
        setSuccess(t('Customer successfully removed'))
        if (onSuccess) onSuccess()
      }
    } catch (err) {
      console.log('error removing customer', err)
    } finally {
      setLoading(false)
    }
  }

  const hasErrors = () => {
    setFormErrors(initialState)
    const errors = initialState
    let hasErrors = false
    for (const [key, value] of Object.entries(customerItem)) {
      if (!value || value.length < 1) {
        errors[key] = t('Please fill in the field')
        hasErrors = true
      }
    }
    setFormErrors(errors)
    return hasErrors
  }

  return (
        <Modal
          title={title}
          show={show}
          close={close}
        >
          <div className="edit-user">
            <form className="edit-user--form">
              <Input type={'text'} placeholder={ t('Name') } label={ t('Name') } onChange={(e) => setField('name', e.target.value)} error={formErrors.name} value={customerItem.name ? customerItem.name : ''} />
              <Input type={'text'} placeholder={ t('Phone') } label={ t('Phone') } onChange={(e) => setField('phone', e.target.value)} error={formErrors.phone} value={customerItem.phone ? customerItem.phone : ''} />
              <Input type={'email'} placeholder={ t('Email') } label={ t('Email') } onChange={(e) => setField('email', e.target.value)} error={formErrors.email} value={customerItem.email ? customerItem.email : ''} />
              <Input type={'text'} placeholder={ t('Address') } label={ t('Address') } onChange={(e) => setField('address', e.target.value)} error={formErrors.address} value={customerItem.address ? customerItem.address : ''} />
            </form>
            {
              updateCustomerError &&
              <div className='add-error'>{ updateCustomerError && updateCustomerError.messaage }</div>
            }
            {
              success &&
              <div className='add-success'>{ success }</div>
            }
            <div className='actions'>
              <Button
                secondary={true}
                disabled={parseInt(user.id) === parseInt(customerItem.id)}
                className="btn"
                label={ removeText ? removeText : t('Remove customer') }
                loading={loading}
                onClick={(e) => {
                  e.preventDefault()
                  removeCustomer()
                }}
              />
              <Button
                className="btn"
                label={ t('Update customer') }
                loading={loading}
                onClick={(e) => {
                  e.preventDefault()
                  update()
                }}
              />
            </div>
          </div>
        </Modal>
  )
}

export default EditCustomer
