import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import './Users.scss';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_USER, UPDATE_USER } from '../../graphql/mutations';
import { GET_ALL_USERS, GET_CUSTOMER_USERS, GET_DEALERSHIP_USERS } from '../../graphql/queries';
import EmptyResult from '../../components/EmptyResult/EmptyResult';
import Input from '../../components/Input/Input';
import { useAuth } from '../../hooks/useAuth';
import Pagination from '../../components/Pagination/Pagination';
import Button from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import EditIcon from '../../components/Icons/edit';
import EditUser from '../../components/EditUser/EditUser';
import { useNavigate } from 'react-router';

const userState = {
  name: '',
  email: '',
  phone: '',
  pid: '',
}

const Users = () => {

  const { t } = useTranslation()
  const { logout, currentDealership, user, currentCustomer } = useAuth()

  const [tableHeaders, setTableHeaders] = useState([
    {
      label: t('Name'),
      key: 'name',
    },
    {
      label: t('Email'),
      key: 'email',
    },
    {
      label: t('Phone'),
      key: 'phone',
    },
    {
      label: t('PID'),
      key: 'pid',
    },
  ])
  const [modal, setModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userErrors, setUserErrors] = useState(userState)
  const [userEditErrors, setEditUserErrors] = useState(userState)
  const [newUser, setNewUser] = useState(userState)
  const [editUser, setEditUser] = useState(userState)
  const [editSuccess, setEditSuccess] = useState(null)
  const [users, setUsers] = useState([])
  const navigate = useNavigate()
  const page = useRef(0)
  const limit = useRef(10)

  const openModal = () => setModal(true);

  const [createUser, { error: createUserError, reset: resetCreateUser }] = useMutation(CREATE_USER, {
    errorPolicy: 'all',
  })
  const [updateUserMutation, { error: editUserError, reset: resetEditUser }] = useMutation(UPDATE_USER, {
    errorPolicy: 'all',
  })
  const { refetch } = useQuery(user.role === 'Superadmin' ? GET_ALL_USERS : (user.role === 'User' ? GET_CUSTOMER_USERS : GET_DEALERSHIP_USERS), {
    fetchPolicy: 'no-cache',
    variables: {
      ...(user.role !== 'User' && {
        page: page.current,
        limit: limit.current,
      }),
      ...(user.role === 'Admin' && {
        dealership: currentDealership.id,
      }),
      ...(user.role === 'User' && {
        dealershipId: currentDealership.id,
        customerId: currentCustomer.id,
      }),
    },
  })

  useEffect(() => {
    getUserList()

    if (user.role !== 'User') {
      setTableHeaders([
        ...tableHeaders,
        {
          label: t('Actions'),
          key: 'actions',
        },
      ])
    }
  }, [])

  useEffect(() => {
    getUserList()
  }, [currentDealership])

  const addUser = async () => {
    if (formErrors()) return
    setLoading(true)
    try {
      const variables = {
        ...newUser,
        ...(user.role === 'Admin' && {
          dealership: currentDealership.id,
        }),
      }

      const res = await createUser({
        variables,
      })
      if (res.data?.createUser) {
        getUserList()
        closeModal()
      }
    } catch (err) {
      console.log('error creating user', err)
    } finally {
      setLoading(false)
    }
  }

  const updateUser = async () => {
    if (editFormErrors()) return
    setLoading(true)
    try {
      const variables = {
        ...editUser,
        ...(user.role === 'Admin' && {
          dealershipId: currentDealership.id,
        }),
      }

      const res = await updateUserMutation({
        variables,
      })
      if (res.data?.updateUser) {
        getUserList()
        setEditSuccess(t('User successfully updated'))
      }
    } catch (err) {
      console.log('error creating user', err)
    } finally {
      setLoading(false)
    }
  }

  const removeUser = async () => {
    setLoading(true)
    try {
      const variables = {
        ...editUser,
        ...(user.role === 'Admin' && {
          dealershipId: currentDealership.id,
        }),
        remove: true,
      }

      const res = await updateUserMutation({
        variables,
      })
      if (res.data?.updateUser) {
        getUserList()
        setEditSuccess(t('User successfully removed'))
      }
    } catch (err) {
      console.log('error removing user', err)
    } finally {
      setLoading(false)
    }
  }

  const getUserList = async () => {
    try {
      const res = await refetch()

      if (res.data) {
        if (user.role === 'Superadmin') setUsers(res.data.getAllUsers)
        if (user.role === 'Admin') setUsers(res.data.getDealershipUsers)
        if (user.role === 'User') setUsers({
          rows: res.data.getCustomerUsers,

        })
      }
    } catch (err) {
      if (err && err.message.includes('Invalid credentials')) {
        return logout()
      }
    }
  }

  const setField = (field, value) => {
    resetCreateUser()
    const userData = newUser
    userData[field] = value
    setUserErrors({
      ...userErrors,
      [field]: null,
    })
    setNewUser(userData)
  }

  const setEditField = (field, value) => {
    resetEditUser()
    const userData = editUser
    userData[field] = value
    setEditUserErrors({
      ...userEditErrors,
      [field]: null,
    })
    setEditUser(userData)
  }

  const formErrors = () => {
    setUserErrors(userState)
    const errors = userState
    let hasErrors = false
    for (const [key, value] of Object.entries(newUser)) {
      if (key === 'pid') continue
      if (!value || value.length < 1) {
        errors[key] = t('Please fill in the field')
        hasErrors = true
      }
    }
    setUserErrors(errors)
    return hasErrors
  }

  const editFormErrors = () => {
    setEditUserErrors(userState)
    const errors = userState
    let hasErrors = false
    for (const [key, value] of Object.entries(editUser)) {
      if (!value || value.length < 1) {
        errors[key] = t('Please fill in the field')
        hasErrors = true
      }
    }
    setEditUserErrors(errors)
    return hasErrors
  }

  const closeModal = () => {
    resetCreateUser()
    setNewUser(userState)
    setUserErrors(userState)
    setModal(false)
  }

  const closeEditModal = () => {
    resetEditUser()
    setEditUser(userState)
    setEditUserErrors(userState)
    setEditModal(false)
  }

  const getPreviousPage = async () => {
    page.current = page.current - 1
    const res = await refetch({
      page: page.current,
        limit: limit.current,
        ...(user.role !== 'Superadmin' && {
          dealership: currentDealership.id,
      }),
    })
    if (res.data) {
      setUsers(user.role === 'Superadmin' ? res.data.getAllUsers : res.data.getDealershipUsers)
    }
  }

  const getNextPage = async () => {
    page.current = page.current + 1
    const res = await refetch({
      page: page.current,
        limit: limit.current,
        ...(user.role !== 'Superadmin' && {
          dealership: currentDealership.id,
      }),
    })
    if (res.data) {
      setUsers(user.role === 'Superadmin' ? res.data.getAllUsers : res.data.getDealershipUsers)
    }
  }

  return (
    <>
      <div className="view users-view">
        <div className="inner">
          <div className="view-title">
            <h1>{ t('Users') }</h1>
            {
              user.role !== 'User' &&
              <button className="btn" onClick={() => openModal()}>
                { t('Add user') }
              </button>
            }
          </div>
          { users && users.rows && users.rows.length > 0 ?
          <div className="view-table">
            <div className={ `view-table--content cols-3` } style={{
                gridTemplateColumns: `repeat(${tableHeaders.length}, minmax(100px, 1fr))`
              }}>
              { tableHeaders.map(x => 
                (
                  <div
                    key={x.label}
                    className={`view-table--header-item ${'view-table--header-item-' + x.key}`}
                  >
                    { x.label }
                  </div>
                ) 
              )}
              { users.rows.map((x, index) => {
                  return tableHeaders.map((i, hIndex) => (
                    <div key={`${x.name}-${i.key}-${hIndex}-${index}`} className={`view-table--content-item ${'view-table--content-item-' + i.key} ${index % 2 === 1 ? 'odd' : 'even'}`}>
                      { user.role !== 'User' && i.key === 'actions' ?
                        <div>
                          <button className='btn btn-small' onClick={() => {
                            setEditUser({
                              id: x.id,
                              name: x.name,
                              email: x.email,
                              phone: x.phone,
                            })
                            setEditModal(true)
                          }}>
                            <EditIcon />
                          </button>
                        </div>
                        :
                        <span>{ x[i.key] }</span>
                      }
                    </div>
                  ))}
              )}
            </div>
            <Pagination total={users.count} onPrev={getPreviousPage} onNext={getNextPage} current={page.current} limit={limit.current} />
          </div>
          :
          <EmptyResult message={ t('No users added yet') } />
          }
        </div>
        <Modal
          title={t('Add user')}
          show={ modal }
          close={() => closeModal()}
        >
          <div className="users-view--add">
            <form className="users-view--add-form">
              <Input type={'text'} placeholder={ t('Name') } onChange={(e) => setField('name', e.target.value)} error={userErrors.name} value={newUser.name} />
              <Input type={'text'} placeholder={ t('Phone') } onChange={(e) => setField('phone', e.target.value)} error={userErrors.phone} value={newUser.phone} />
              <Input type={'email'} placeholder={ t('Email') } onChange={(e) => setField('email', e.target.value)} error={userErrors.email} value={newUser.email} />
              <Input type={'number'} placeholder={ t('Personal identification number') } onChange={(e) => setField('pid', e.target.value)} error={userErrors.pid} value={newUser.pid} />
            </form>
            {
              createUserError &&
              <div className='add-error'>{ createUserError.message }</div>
            }
            <Button
              className="btn"
              label={ t('Add user') }
              loading={loading}
              onClick={(e) => {
                e.preventDefault()
                addUser()
              }}
            />
          </div>
        </Modal>
        <EditUser
          title={t('Edit user')}
          show={ editModal }
          close={() => closeEditModal()}
          setField={setEditField}
          userItem={editUser}
          onSuccess={() => getUserList()}
        />
      </div>
    </>
  );
}

export default Users;
