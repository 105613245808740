import React, { useState } from 'react';
import './ResetPassword.scss';
import Button from '../../components/Button/Button';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from '../../graphql/mutations';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const [email, setEmail] = useState()
  const [emailError, setEmailError] = useState(null)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const [resetPassword] = useMutation(RESET_PASSWORD)

  const validateFields = () => {
    clearErrors()
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(t('Invalid email address'))
      return false
    }
    return true
  }

  const clearErrors = () => {
    setEmailError(null)
    setError(false)
  }

  const reset = async (event) => {
    event.preventDefault()
    if (!validateFields()) return
    setLoading(true)
    try {
      const res = await resetPassword({
        variables: {
          email,
        },
      })
      console.log(res)
      if (!res || res.data?.resetPassword === 'FAIL') {
        return setError(true)
      }
      if (res && res.data?.resetPassword === 'SUCCESS') {
        return setSuccess(true)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="login-view">
        <div className="login-view--inner">
          <div className="login-view--logo"></div>
          <h3>{ t('Reset password') }</h3>
          {
            !success ?
            <form>
              <div className="input-wrapper">
                <input type="email" placeholder="Email" onChange={(event) => setEmail(event.target.value)} />
                { emailError && <div className="input-wrapper--error">{ emailError }</div> }
              </div>
              { error && <div className="login-error">{ t('Error resetting password') }</div> }
              <Button
                label={ t('Reset password') }
                onClick={reset}
                loading={loading}
              />
              <Link to={'/login'}>{ t('Log in') }</Link>
            </form>
            :
            <div className="success">
              <h5>{ `${t('New password sent to')} ${email}` }</h5>
              <Link to={'/login'}>{ t('Log in') }</Link>
            </div>
          }   
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
