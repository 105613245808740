import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import './Footer.scss';

const Footer = () => {
  const { currentDealership } = useAuth()

  return (
    <footer className='footer'>
      <div className="inner">
        <div className="logo"></div>
        
          { currentDealership &&
            <div className='footer--contact-info'>
              { currentDealership.name && <p>{ currentDealership.name }</p> }
              { currentDealership.email && <a href={`mailto:${currentDealership.email}`}>{ currentDealership.email }</a> }
              { currentDealership.phone && <a href={`tel:${currentDealership.phone}`}>{ currentDealership.phone }</a> }
            </div>
          }
       
      </div>
    </footer>
  );
}

export default Footer;
