import React, { useEffect, useRef, useState } from 'react';
import './Invoices.scss';
import { GET_INVOICE, GET_INVOICES } from '../../graphql/queries';
import { useQuery } from '@apollo/client';
import EmptyResult from '../../components/EmptyResult/EmptyResult';
import { base64toBlob, formatDate } from '../../helpers/format';
import { useAuth } from '../../hooks/useAuth';
import Pagination from '../../components/Pagination/Pagination';
import { useTranslation } from 'react-i18next';

const Invoices = () => {

  const { t } = useTranslation()
  const { logout, currentDealership, currentCustomer } = useAuth()
  const page = useRef(0)
  const limit = useRef(50)
  const [loadingPdfs, setLoadingPdfs] = useState([])
  const { data, refetch, error } = useQuery(GET_INVOICES, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    variables: {
      page: page.current,
      limit: limit.current,
      dealership: currentDealership?.id,
      customer: currentCustomer?.id,
    },
  })

  const { refetch: getInvoice } = useQuery(GET_INVOICE, {
    errorPolicy: 'all',
  })

  useEffect(() => {
    if (error && error.message.includes('Invalid credentials')) {
      return logout()
    }
  }, [error])

  const tableHeaders = [
    {
      label: t('Invoice nr'),
      key: 'invoiceId',
    },
    {
      label: t('Date'),
      key: 'invoiceDate',
    },
    {
      label: t('Due date'),
      key: 'invoiceDueDate',
    },
    {
      label: t('Sum'),
      key: 'invoiceNetAmount',
    },
    {
      label: t('VAT'),
      key: 'invoiceVatAmount',
    },
    {
      label: t('Sum with VAT'),
      key: 'invoiceAmount',
    },
    {
      label: t('PDF'),
      key: 'link',
    },
  ]

  const getPreviousPage = () => {
    page.current = page.current - 1
    refetch({
      page: page.current,
      limit: limit.current,
    })
  }

  const getNextPage = () => {
    page.current = page.current + 1
    refetch({
      page: page.current,
      limit: limit.current,
    })
  }

  const handlePdf = async (invoiceId) => {
    setLoadingPdfs([
      ...loadingPdfs,
      invoiceId,
    ])
    try {
      const { data } = await getInvoice({
        invoiceId,
        dealership: currentDealership.id,
        customer: currentCustomer.id,
      })
      if (data && data.getInvoice) {
        const blob = base64toBlob(data.getInvoice)
        const url = URL.createObjectURL(blob)
        window.open(url, '_blank')
      }
    } catch (err) {
      console.log('handlePdf', err)
    } finally {
      const filtered = loadingPdfs.filter(x => x !== invoiceId)
      setLoadingPdfs(filtered)
    }
  }

  return (
    <div className="view invoices-view">
      <div className="inner">
        <div className="view-title">
          <h1>{ t('Invoices') }</h1>
        </div>
        { data && data.getInvoices && data.getInvoices.rows && data.getInvoices.rows.length > 0 ?
          <div className="view-table">
            <div className={ `view-table--content` } style={{
                gridTemplateColumns: `repeat(${tableHeaders.length}, minmax(100px, 1fr))`
              }}>
              { tableHeaders.map(x => 
                (
                  <div
                    key={x.label}
                    className={`view-table--header-item ${'view-table--header-item-' + x.key}`}
                  >
                    { x.label }
                  </div>
                ) 
              )}
              { data && data.getInvoices && data.getInvoices.rows && data.getInvoices.rows.map((x, index) => {
                  return tableHeaders.map((i, hIndex) => (
                    <div key={`${x.invoiceId}-${index}-${hIndex}-${i.key}`} className={`view-table--content-item ${'view-table--content-item-' + i.key} ${index % 2 === 1 ? 'odd' : 'even'}`}>
                      {
                        i.key === 'link' ?
                        <a onClick={() => handlePdf(x[i.key])} className={`pdf-download${loadingPdfs.includes(x[i.key]) ? 'loading' : ''}` } target="_blank">{ loadingPdfs.includes(x[i.key]) ? t('Downloading..') : t('Download') }</a>
                        :
                        <>
                          {
                            ['invoiceDate', 'invoiceDueDate'].includes(i.key) ?
                            <span>{ formatDate(x[i.key]) }</span>
                            :
                            <>
                              <span>{ x[i.key] }</span>
                              { ['invoiceNetAmount', 'invoiceVatAmount', 'invoiceAmount'].includes(i.key) && (<span className='currency'> EUR</span>)}
                            </>
                          }
                        </>
                      }
                    </div>
                  ))}
              )}
            </div>
            <Pagination total={data.getInvoices.count} onPrev={getPreviousPage} onNext={getNextPage} current={page.current} limit={limit.current} />
          </div>
          :
          <EmptyResult message={ t('There are currently no invoices') } />
        }
      </div>
    </div>
  );
};

export default Invoices;
