const exportedStyle = async () => {
    if (window.location.href.indexOf('skoda') > -1) {
        await import('../styles/global-skoda.scss')
        document.body.classList.add('skoda')
        return
    }
    if (window.location.href.indexOf('porsche') > -1) {
        await import('../styles/global-porsche.scss')
        document.body.classList.add('porsche')
        return
    }
    if (window.location.href.indexOf('volkswagen') > -1) {
        await import('../styles/global-vw.scss')
        document.body.classList.add('vw')
        return
    }
    await import('../styles/global.scss')
    document.body.classList.add('auto100')
}

export default exportedStyle