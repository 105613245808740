import { useMutation } from "@apollo/client"
import React, { useState, useEffect, useContext, createContext } from "react"
import { LOGIN, VALIDATE_TOKEN } from "../graphql/mutations"
import jwtDecode from "jwt-decode"
import { useNavigate } from "react-router-dom"

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
};

const useProvideAuth = () => {
  const [user, setUser] = useState(null);
  const [currentDealership, setCurrentDealership] = useState(null)
  const [currentCustomer, setCurrentCustomer] = useState(null)
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      await validate()
      setLoading(false)
    })()
  }, [])

  const [signin] = useMutation(LOGIN)
  const [validateToken] = useMutation(VALIDATE_TOKEN)

  const login = async (email, password) => {
    try {
      const res = await signin({
        variables: {
          email,
          password,
        },
      })
  
      if (res.data?.login && res.data.login.token) {
        localStorage.setItem('token', res.data.login.token)
        const isValid = await validate()
        return isValid
      }
    } catch (err) {
      console.log(err)
      return false
    }
    return false
  }

  const validate = async () => {
    const token = localStorage.getItem('token')
    if (!token) return false

    try {
      const res = await validateToken({
        variables: {
          token,
        },
      })
      if (res.data?.validateToken && res.data.validateToken.valid) {
        const data = jwtDecode(token)

        if (data.dealerships && data.dealerships.length > 0) {
          setCurrentDealership(data.dealerships[0])
        }

        if (data.customers && data.customers.length > 0) {
          setCurrentCustomer(data.customers[0])
          setCurrentDealership(data.customers[0].Dealerships[0])
        }

        setUser(data)
        setLoggedIn(true)
        return true
      }
    } catch (err) {
      logout()
    }
    return false
  }

  const logout = () => {
    localStorage.removeItem('token')
    setUser(null)
    setLoggedIn(false)
    navigate('/login')
  }

  return {
    user,
    loggedIn,
    loading,
    login,
    validate,
    logout,
    setCurrentDealership,
    currentDealership,
    setCurrentCustomer,
    currentCustomer,
  };
}