import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { setContext } from 'apollo-link-context'
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { from } from '@apollo/client'

import './styles/elements.scss'
import exportedStyle from './helpers/style'
import { ProvideAuth } from './hooks/useAuth'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import I18NextChainedBackend from 'i18next-chained-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import { en, et } from './translations'

exportedStyle()

const bundledResources = {
  en: {
    translations: en,
  },
  et: {
    translations: et,
  },
}

i18n
  .use(I18NextChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['translations'],
    defaultNS: 'translations',
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend(bundledResources),
      ],
      backendOptions: [
        {
          loadPath: 'https://spareparts.skoda.ee/translations/{{lng}}.json',
          crossDomain: true,
        },
      ],
    },
  })

/**
 * Basic error reporting since otherwise it would only show respective response code with no actual way to debug internal errors.
 */

const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
  console.log(graphQLErrors)
})

const httpLink = new HttpLink({
  uri: process.env.NODE_ENV === 'development' ? 'http://localhost:3050/graphql' : 'https://api.spareparts.skoda.ee/graphql',
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
})

const client = new ApolloClient({
  link: from([
    authLink,
    errorLink,
    httpLink,
  ]),
  connectToDevTools: process.env.NODE_ENV === 'development',
  cache: new InMemoryCache(),
})

ReactDOM.createRoot(document.getElementById('root')).render(
    <ApolloProvider client={client}>
        <BrowserRouter>
          <ProvideAuth>
            <Suspense fallback="loading">
              <App />
            </Suspense>
          </ProvideAuth>
        </BrowserRouter>
    </ApolloProvider>
)
serviceWorker.unregister()
