import React from 'react'

const UsersIcon = ({ fill }) => {

    return (
        <div>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 492.308 492.308" fill={fill ? fill : '#000'}>
                <path d="M492.308,356.865c0-73.547-41.636-138.937-107.13-170.163c12.648-17.293,19.591-38.065,19.591-59.789
                    c0-55.808-45.404-101.212-101.212-101.212c-47.137,0-87.701,32.655-98.438,77.484c-5.339-0.874-10.778-1.446-16.361-1.446
                    c-55.875,0-101.327,45.404-101.327,101.212c0,18.39,4.895,35.939,14.126,51.531C41.148,277.487,0,336.246,0,401.779v64.827h377.51
                    v-64.827c0-3.766-0.201-7.496-0.468-11.212h115.266V356.865z M303.558,45.394c44.952,0,81.519,36.567,81.519,81.519
                    c0,21.048-7.981,41.058-22.288,56.135l-0.471,0.471l6.962,6.961l-7.058-6.865c-15.625,16.077-36.462,24.933-58.663,24.933
                    c-4.611,0-9.206-0.536-13.772-1.321c0.058-1.425,0.175-2.839,0.175-4.275c0-43.355-27.448-80.322-65.839-94.71
                    C232.59,71.909,265.407,45.394,303.558,45.394z M188.76,121.433c44.942,0,81.51,36.567,81.51,81.519
                    c0,18.529-6.077,36.058-17.567,50.683c-5.837,7.377-12.763,13.573-20.462,18.438c-0.507,0.32-1.057,0.564-1.57,0.873
                    c-2.085,1.254-4.186,2.483-6.373,3.541c-1.196,0.579-2.457,1.013-3.68,1.532c-1.566,0.664-3.102,1.395-4.709,1.96
                    c-2.447,0.861-4.961,1.544-7.493,2.169c-0.43,0.106-0.841,0.269-1.273,0.368c-2.918,0.669-5.886,1.17-8.891,1.517
                    c-0.062,0.007-0.121,0.025-0.183,0.031c-3.068,0.347-6.175,0.523-9.31,0.523c-3.144,0-6.261-0.176-9.337-0.523
                    c-18.457-2.082-35.484-10.309-48.55-23.459c-2.174-2.189-4.238-4.514-6.18-6.969c-11.49-14.625-17.567-32.154-17.567-50.683
                    C107.125,158,143.75,121.433,188.76,121.433z M357.817,446.914H19.692v-45.135c0-59.173,38.327-112.048,93.962-130.731
                    c0.557,0.615,1.182,1.144,1.751,1.743c1.17,1.232,2.373,2.415,3.596,3.58c1.457,1.388,2.942,2.73,4.471,4.022
                    c1.281,1.082,2.575,2.133,3.903,3.147c1.594,1.217,3.225,2.366,4.883,3.483c1.37,0.921,2.739,1.832,4.15,2.683
                    c1.728,1.044,3.499,1.998,5.285,2.934c1.435,0.752,2.86,1.514,4.33,2.196c1.873,0.87,3.793,1.624,5.718,2.377
                    c1.463,0.572,2.907,1.177,4.398,1.681c2.057,0.695,4.162,1.248,6.267,1.81c1.427,0.381,2.831,0.822,4.278,1.141
                    c2.371,0.523,4.79,0.873,7.209,1.227c1.234,0.18,2.444,0.445,3.689,0.581c3.686,0.401,7.412,0.627,11.175,0.627
                    c3.755,0,7.474-0.225,11.153-0.627c1.23-0.134,2.425-0.397,3.644-0.575c2.428-0.355,4.855-0.709,7.236-1.234
                    c1.429-0.315,2.815-0.752,4.225-1.129c2.118-0.566,4.234-1.124,6.305-1.825c1.469-0.497,2.891-1.095,4.333-1.659
                    c1.946-0.761,3.885-1.525,5.777-2.405c1.443-0.671,2.841-1.421,4.251-2.16c1.811-0.95,3.605-1.918,5.357-2.978
                    c1.386-0.838,2.731-1.734,4.077-2.639c1.678-1.13,3.328-2.294,4.94-3.528c1.312-1.003,2.591-2.043,3.857-3.114
                    c1.538-1.3,3.033-2.651,4.499-4.049c1.218-1.162,2.414-2.34,3.579-3.567c0.571-0.602,1.199-1.134,1.758-1.752
                    c55.692,18.596,94.067,71.471,94.067,130.74V446.914z M472.615,370.875h-98.317c-10.623-52.624-47.683-97.167-98.45-116.406
                    c5.147-8.697,8.95-17.996,11.28-27.731c5.448,0.899,10.929,1.502,16.43,1.502c25.249,0,48.99-9.297,67.694-26.152
                    c61.737,26.877,101.364,86.911,101.364,154.777V370.875z"/>
            </svg>
        </div>
    )
}

export default UsersIcon