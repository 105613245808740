import React from 'react'
import './Button.scss'

const Button = ({ onClick, loading, label, className, disabled, secondary, icon }) => {

  return (
    <button className={`btn btn-component${icon ? ' btn--has-icon' : ''} ${loading ? 'loading': ''} ${className ? className : ''} ${secondary ? 'btn-secondary' : ''}`} onClick={onClick} disabled={disabled}>
      {
        loading ?
        <div className="loader"></div>
        :
        <>
          {
            icon ? <div className='btn--icon'>{ icon }</div> : <></>
          }
          <span>{ label }</span>
        </>
      }
    </button>
  )
}

export default Button