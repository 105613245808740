import ReactDOM from 'react-dom'
import React, { useEffect } from 'react'
import Button from './Button/Button'

const ConfirmModal = ({ show, close, title, children, className, onConfirm, confirmLabel, cancelLabel }) => {

  useEffect(() => {
    document.body.addEventListener('keyup', handleEsc)

    return () => {
      document.body.removeEventListener('keyup', handleEsc)
    }
  }, [])

  const handleEsc = (e) => {
    if (e.key === 'Escape') {
      close()
    }
  }

  return ReactDOM.createPortal(
    <>
      { show &&
        <>
          <div onClick={close} className='modal-background'></div>
          <div
            className={`modal-inner${className ? ' ' + className : ''}`}
          >
            <div className='modal-header'>
              <div className='modal-header--inner'>
                <button className="modal-close" onClick={close}></button>
                <h2 className="modal-title">{title}</h2>
              </div>
            </div>
            <div className="modal-content">{children}</div>
            <div className='modal-actions'>
              <Button
                label={cancelLabel}
                onClick={close}
                className={'btn-cancel'}
              />
              <Button
                label={confirmLabel}
                onClick={onConfirm}
                className={'btn-confirm'}
              />
            </div>
          </div>
        </>
      }
    </>,
    document.getElementById('confirm-modal'),
  )
}

export default ConfirmModal
