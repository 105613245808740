import { t } from 'i18next'
import React, { useState } from 'react'
import Button from '../Button/Button'
import Input from '../Input/Input'
import './AddToOrder.scss'

const AddToOrder = ({ add, item, loading, focus }) => {

  const [quantity, setQuantity] = useState('')
  const [description, setDescription] = useState('')

  const addItem = () => {
    if (quantity < 1) return
    add(item, quantity, description)
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      addItem()
    }
  }

  return (
    <>
      <h5>{ `${t('Quantity')}:` }</h5>
      <Input type={'number'} placeholder={ t('Insert quantity') } value={quantity} onChange={(e) => setQuantity(e.target.value)} focus={focus} onKeyUp={handleKey} />
      <div className="input-wrapper prod-description">
        <label htmlFor={`prod-description`}>{ `${t('Comments')}:` }</label>
        <input type={'text'} id="prod-description" placeholder={ t('Type comment') } maxLength={17} value={description} onChange={(e) => setDescription(e.target.value)} onKeyUp={handleKey} />
      </div>
      <Button loading={loading} disabled={quantity < 1} onClick={() => addItem()} label={ t('Add to order') } />
    </>
  )
}

export default AddToOrder