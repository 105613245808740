import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { UPDATE_USER_PASS } from '../graphql/mutations'
import Button from './Button/Button'
import DeleteIcon from './Icons/delete'
import Input from './Input/Input'

const UsersListItem = ({
    data,
    onDelete,
    onEdit,
}) => {

    const { t } = useTranslation()
    const [password, setPassword] = useState('')
    const [edit, setEdit] = useState(false)
    const [updateUserPass] = useMutation(UPDATE_USER_PASS, {
        errorPolicy: 'all',
    })

    const handleUpdatePassword = async () => {
        if (!password) return

        const { data: res } = await updateUserPass({
            variables: {
                userId: data?.id,
                newPassword: password,
            },
        })

        if (res?.changeUserPassword !== 'SUCCESS') return

        setPassword('')
        setEdit(false)
    }

    const handleDelete = () => {
        if (onDelete) onDelete(data)
    }

    const handleEdit = () => {
        if (onEdit) {
            onEdit(data)
            return
        }
        setEdit(!edit)
    }

    return (
        <div className='users-list-view--user'>
            <div className='users-list-view--user-info'>
                <span>{`${data.name}`}</span>
                <span><a href={`mailto:${data.email}`}>{`${data.email}`}</a></span>
                <a onClick={handleEdit}>{t('Edit')}</a>
            </div>
            {
                edit ?
                    <>
                        <div className='users-list-view--user-fields'>
                            <Input
                                placeholder={t('Password')}
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                type={'password'}
                            />
                        </div>
                        <div className='users-list-view--user-actions'>
                            <Button
                                icon={<DeleteIcon />}
                                className={'btn-small'}
                                onClick={handleDelete}
                                label={t('Remove')}
                            />
                            <Button
                                label={t('Update user')}
                                onClick={handleUpdatePassword}
                            />
                        </div>
                    </>
                    :
                    <></>
            }
        </div>
    )
}

export default UsersListItem