const formatDate = (date) => {
  return (new Date(date)).toLocaleDateString('et', {
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
  })
}

const formatPrice = (price) => {
  return (Math.round(price * 100) / 100).toFixed(2)
}

const base64toBlob = (data) => {
  var binary = atob(data.replace(/\s/g, ''));
  var len = binary.length;
  var buffer = new ArrayBuffer(len);
  var view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
  }
  return new Blob([view], { type: 'application/pdf' })
}

export {
  formatDate,
  formatPrice,
  base64toBlob,
}