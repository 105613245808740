import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';
import './Modal.scss';

const Modal = ({ show, close, title, children, className }) => {

  useEffect(() => {
    document.body.addEventListener('keyup', handleEsc)

    return () => {
      document.body.removeEventListener('keyup', handleEsc)
    }
  }, [])

  const handleEsc = (e) => {
    if (e.key === 'Escape') {
      close()
    }
  }

  return ReactDOM.createPortal(
    <>
      { show &&
        <>
          <div className="modal-background"></div>
          <div
            className={`modal-inner${className ? ' ' + className : ''}`}
          >
            <button className="modal-close" onClick={close}></button>
            <h2 className="modal-title">{title}</h2>
            <div className="modal-content">{children}</div>
          </div>
        </>
      }
    </>,
    document.getElementById('modal'),
  );
};

export default Modal;
