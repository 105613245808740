import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from './Modal/Modal'
import { DeliveryMethod } from '../helpers/const'
import { useAuth } from '../hooks/useAuth'
import Input from './Input/Input'

const DeliveryModal = ({
    show,
    close,
    onConfirm,
}) => {

    const { t } = useTranslation()
    const { currentDealership, currentCustomer } = useAuth()
    const [selected, setSelected] = useState(null)
    const [address, setAddress] = useState(currentCustomer?.address)
    const deliveryMethods = useRef([
        {
            value: DeliveryMethod.Courier,
            label: t('Courier'),
        },
        {
            value: DeliveryMethod.Dealership,
            label: currentDealership?.name,
        },
        {
            value: DeliveryMethod.Cup,
            label: t('Porsche Race track'),
        },
    ]).current

    const renderMethod = (label, type) => {
        if (type === DeliveryMethod.Courier) {
            return (
                <div className='delivery-method--input'>
                    <span>{ label }</span>
                    <Input
                        placeholder={address}
                        value={address === currentDealership?.address ? '' : address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
            )
        }
        return (
            <span>{ label }</span>
        )
    }

    return (
        <Modal
            title={ t('Choose delivery method') }
            show={show}
            close={close}
            className='delivery-modal'
        >
            <div className="delivery-methods">
                {
                    deliveryMethods.map(x => 
                        <div
                            key={`method-${x.value}`}
                            className={`delivery-method${selected === x.value ? ' selected' : ''}`}
                            onClick={() => setSelected(x.value)}
                        >
                            <div className='selection'></div>
                            {
                                renderMethod(x.label, x.value)
                            }
                        </div>    
                    )
                }
            </div>
            <button
                onClick={() => {
                    if (!selected) return
                    if (onConfirm) {
                        if (selected === DeliveryMethod.Courier) {
                            onConfirm(address)
                        }
                        if (selected === DeliveryMethod.Dealership) {
                            onConfirm(currentDealership.name)
                        }
                        if (selected === DeliveryMethod.Cup) {
                            onConfirm('Porsche Race track')
                        }
                    }
                    setSelected(null)
                }}
                className='btn btn-component'
                disabled={!selected || (selected === DeliveryMethod.Courier && !address)}
            >
                { t('Send order') }
            </button>
        </Modal>
    )
}

export default DeliveryModal