import React, { useEffect, useState } from 'react'
import './EditUser.scss'
import Modal from '../Modal/Modal'
import Input from '../Input/Input'
import Button from '../Button/Button'
import { useTranslation } from 'react-i18next'
import { UPDATE_USER } from '../../graphql/mutations'
import { useAuth } from '../../hooks/useAuth'
import { useMutation } from '@apollo/client'

const EditUser = ({ show, close, title, setField, userItem, onSuccess, removeText, dealership, isCustomer }) => {

  const userState = {
    name: '',
    phone: '',
    email: '',
    pid: '',
    password: '',
  }
  const { t } = useTranslation()
  const { user, currentDealership } = useAuth()
  const [formErrors, setFormErrors] = useState(userState)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)

  const [updateUserMutation, { error: updateUserError, reset: resetUpdateUser }] = useMutation(UPDATE_USER, {
    errorPolicy: 'all',
  })

  useEffect(() => {
    return () => {
      resetUpdateUser()
      setFormErrors(userState)
      setLoading(false)
      setSuccess(null)
    }
  }, [])

  useEffect(() => {
    if (!show) {
      resetUpdateUser()
      setFormErrors(userState)
      setLoading(false)
      setSuccess(null)
    }
  }, [show])

  if (!userItem) return (<></>)

  const updateUser = async () => {
    if (hasErrors()) return
    setLoading(true)

    try {
      const variables = {
        ...userItem,
        ...(user.role === 'Admin' && {
          dealershipId: currentDealership.id,
        }),
      }

      const res = await updateUserMutation({
        variables,
      })

      if (res.data?.updateUser) {
        setSuccess(t('User successfully updated'))
        if (onSuccess) onSuccess()
      }
    } catch (err) {
      console.log('error creating user', err)
    } finally {
      setLoading(false)
    }
  }

  const removeUser = async () => {
    setLoading(true)
    try {
      const variables = {
        ...userItem,
        ...(user.role === 'Admin' && {
          dealershipId: currentDealership.id,
        }),
        ...(user.role === 'Superadmin' && dealership && dealership.id && {
          dealershipId: dealership.id,
        }),
        remove: true,
      }

      const res = await updateUserMutation({
        variables,
      })

      if (res.data?.updateUser) {
        setSuccess(t('User successfully removed'))
        if (onSuccess) onSuccess()
      }
    } catch (err) {
      console.log('error removing user', err)
    } finally {
      setLoading(false)
    }
  }

  const hasErrors = () => {
    setFormErrors(userState)
    const errors = userState
    let hasErrors = false
    for (const [key, value] of Object.entries(userItem)) {
      if (key === 'password') {
        if (value && value?.length < 4) {
          errors[key] = t('Please fill in the field')
          hasErrors = true
        }
        continue
      }
      if (!value || value.length < 1) {
        errors[key] = t('Please fill in the field')
        hasErrors = true
      }
    }
    setFormErrors(errors)
    return hasErrors
  }

  return (
        <Modal
          title={title}
          show={show}
          close={close}
        >
          <div className="edit-user">
            <form className="edit-user--form">
              <Input type={'text'} placeholder={ t('Name') } onChange={(e) => setField('name', e.target.value)} error={formErrors.name} value={userItem.name ? userItem.name : ''} />
              <Input type={'text'} placeholder={ t('Phone') } onChange={(e) => setField('phone', e.target.value)} error={formErrors.phone} value={userItem.phone ? userItem.phone : ''} />
              <Input disabled type={'email'} placeholder={ t('Email') } onChange={(e) => setField('email', e.target.value)} error={formErrors.email} value={userItem.email ? userItem.email : ''} />
              <Input disabled type={'number'} placeholder={ t('Personal identification number') } onChange={(e) => setField('pid', e.target.value)} error={formErrors.pid} value={userItem.pid ? userItem.pid : ''} />
              <Input type={'password'} placeholder={ t('Password') } onChange={(e) => setField('password', e.target.value)} error={formErrors.password} value={userItem?.password || ''} />
            </form>
            {
              updateUserError &&
              <div className='add-error'>{ updateUserError && updateUserError.messaage }</div>
            }
            {
              success &&
              <div className='add-success'>{ success }</div>
            }
            <div className='actions'>
              <Button
                secondary={true}
                disabled={parseInt(user.id) === parseInt(userItem.id)}
                className="btn"
                label={ removeText ? removeText : t('Remove user') }
                loading={loading}
                onClick={(e) => {
                  e.preventDefault()
                  removeUser()
                }}
              />
              <Button
                className="btn"
                label={ t('Update user') }
                loading={loading}
                onClick={(e) => {
                  e.preventDefault()
                  updateUser()
                }}
              />
            </div>
          </div>
        </Modal>
  )
}

export default EditUser
