import { t } from 'i18next'
import React from 'react'
import './Pagination.scss'

const Pagination = ({ total, onNext, onPrev, current, limit }) => {

  if (!total) return <></>

  const getTotalPages = () => {
    return Math.ceil(total / limit)
  }

  const getCurrentPage = () => {
    return current + 1
  }

  return (
    <div className="pagination">
      <div className={`pagination-prev ${getCurrentPage() <= 1 ? 'disabled' : ''}`} onClick={onPrev}>{ t('Prev') }</div>
      <div className="pagination-pages">
        <span>{ `${getCurrentPage()}` }</span>
        <span className="pagination-separator">/</span>
        <span>{ `${getTotalPages()}` }</span>
      </div>
      <div className={`pagination-next ${getCurrentPage() >= getTotalPages() ? 'disabled' : ''}`} onClick={onNext}>{ t('Next') }</div>
    </div>
  )
}

export default Pagination