import React from 'react'

const Input = ({type, placeholder, value, onChange, error, focus, onKeyUp, maxLength, disabled, label }) => {

  return (
    <div className={`input-wrapper${error ? ' input-wrapper--error' : ''}`}>
      <label>
        { label ? <div className='input-wrapper--label'>{ label }</div> : <></> }
        <input autoFocus={focus} type={type} placeholder={placeholder} onChange={onChange} value={value} onKeyUp={onKeyUp} maxLength={maxLength} disabled={disabled} />
      </label>
      { error &&
        <div className="input-error">{ error }</div>
      }
    </div>
  )
}

export default Input