import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './screens/Login/Login';
import Dealerships from './screens/Dealerships/Dealerships';
import Users from './screens/Users/Users';
import Customers from './screens/Customers/Customers';
import Orders from './screens/Orders/Orders';
import { useAuth } from './hooks/useAuth'
import Header from './components/Header/Header';
import Order from './screens/Order/Order';
import Invoices from './screens/Invoices/Invoices';
import ResetPassword from './screens/ResetPassword/ResetPassword';
import Footer from './components/Footer/Footer';

function App() {

  const { loggedIn, loading } = useAuth()

  if (loading) {
    return (
      <></>
    )
  }

  return (
    loggedIn ?
      (
        <>
          <Header />
          <Routes>
            <Route path="*" element={<Orders />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/users" element={<Users />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/dealerships" element={<Dealerships />} />
            <Route path="/order" element={<Order />} />
            <Route path="/order/:orderId" element={<Order />} />
          </Routes>
          <Footer />
        </>
      )
      :
      (
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/password" element={<ResetPassword />} />
        </Routes>
      )
  );
}

export default App;
