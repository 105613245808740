import React from 'react'

const EmptyResult = ({message}) => {

  return (
    <div className="empty-result">
      <span>{ message }</span>
    </div>
  )
}

export default EmptyResult