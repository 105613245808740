import gql from 'graphql-tag';

export const GET_DEALERSHIPS = gql`
  query getDealerships ($page: Int!, $limit: Int!) {
    getDealerships (page: $page, limit: $limit) {
      count
      rows {
        id
        name
        email
        brands
        companyId
        phone
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query getCustomers ($page: Int!, $limit: Int!, $dealership: Int!) {
    getCustomers (page: $page, limit: $limit, dealership: $dealership) {
      count
      rows {
        id
        name
        email
        phone
        orderNr
        customerId
        address
      }
    }
  }
`;

export const GET_DEALERSHIP_USERS = gql`
  query getDealershipUsers ($page: Int!, $limit: Int!, $dealership: Int!) {
    getDealershipUsers (page: $page, limit: $limit, dealership: $dealership) {
      count
      rows {
        id
        name
        email
        phone
        pid
      }
    }
  }
`;

export const GET_CUSTOMER_USERS = gql`
  query getCustomerUsers ($customerId: Int!, $dealershipId: Int!) {
    getCustomerUsers (customerId: $customerId, dealershipId: $dealershipId) {
      id
      name
      email
      phone
      pid
    }
  }
`;

export const GET_ALL_USERS = gql`
  query getAllUsers ($page: Int!, $limit: Int!) {
    getAllUsers (page: $page, limit: $limit) {
      count
      rows {
        id
        phone
        name
        email
        pid
      }
    }
  }
`;

export const GET_ORDER = gql`
  query getOrder ($orderId: Int!) {
    getOrder (orderId: $orderId) {
      id
      status
      updatedAt
      description
      deliveryMethod
      items {
        id
        sku
        name
        brand
        quantity
        price
        vatPrice
        customerPrice
        discount
        unit
        status
        deliveryDate
        description
        transId
        stock
        error
      }
    }
  }
`;

export const GET_ORDERS = gql`
  query getOrders ($page: Int!, $limit: Int!, $dealership: Int, $customer: Int, $orderBy: String, $sort: String) {
    getOrders (page: $page, limit: $limit, dealership: $dealership, customer: $customer, orderBy: $orderBy, sort: $sort) {
      count
      rows {
        id
        status
        description
        createdAt
        updatedAt
        user {
          id
          name
          email
        }
        Customer {
          name
        }
      }
    }
  }
`

export const GET_INVOICES = gql`
  query getInvoices ($page: Int!, $limit: Int!, $dealership: Int!, $customer: Int!) {
    getInvoices (page: $page, limit: $limit, dealership: $dealership, customer: $customer) {
        count
        rows {
          invoiceId
          invoiceDate
          invoiceDueDate
          invoiceAmount
          invoiceVatAmount
          invoiceNetAmount
          link
        }
    }
  }
`

export const GET_INVOICE = gql`
  query getInvoice ($dealership: Int!, $customer: Int!, $invoiceId: String) {
    getInvoice (dealership: $dealership, customer: $customer, invoiceId: $invoiceId)
  }
`