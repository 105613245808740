import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import './Customers.scss';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_USER_TO_CUSTOMER, CREATE_CUSTOMER, REMOVE_CUSTOMER_USER } from '../../graphql/mutations';
import { GET_CUSTOMERS, GET_CUSTOMER_USERS } from '../../graphql/queries';
import EmptyResult from '../../components/EmptyResult/EmptyResult';
import Input from '../../components/Input/Input';
import { useAuth } from '../../hooks/useAuth';
import Pagination from '../../components/Pagination/Pagination';
import Button from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import EditIcon from '../../components/Icons/edit';
import EditCustomer from '../../components/EditCustomer/EditCustomer';
import AddUserIcon from '../../components/Icons/add-user';
import UsersIcon from '../../components/Icons/users';
import DeleteIcon from '../../components/Icons/delete';
import ConfirmModal from '../../components/ConfirmModal';
import UsersListItem from '../../components/UsersListItem';

const Customers = () => {

  const { t } = useTranslation()

  const customerState = {
    name: '',
    email: '',
    phone: '',
    orderNr: '',
    customerId: '',
    address: '',
  }

  const customerUserState = {
    name: '',
    email: '',
    phone: '',
    address: '',
    pid: '',
  }

  const tableHeaders = [
    {
      label: t('Name'),
      key: 'name',
    },
    {
      label: t('Email'),
      key: 'email',
    },
    {
      label: t('Phone'),
      key: 'phone',
    },
    {
      label: t('Sales order number'),
      key: 'orderNr',
    },
    {
      label: t('Customer ID'),
      key: 'customerId',
    },
    {
      label: t('Actions'),
      key: 'actions',
    },
  ]

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerErrors, setCustomerErrors] = useState(customerState);
  const [newCustomer, setNewCustomer] = useState(customerState)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [editCustomerModal, setEditCustomerModal] = useState(false)
  const [customers, setCustomers] = useState([])

  const [customerUser, setCustomerUser] = useState(customerUserState)
  const [userModal, setUserModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [selectedCustomerUser,setSelectedCustomerUser] = useState(null)
  const [customerUserErrors, setCustomerUserErrors] = useState(customerUserState)
  const [addUserSuccess, setAddUserSuccess] = useState(null)

  const [usersModal, setUsersModal] = useState(false)

  const { logout, currentDealership } = useAuth()
  const page = useRef(0)
  const limit = useRef(100)

  const openModal = () => setModal(true);

  const [removeCustomerUser] = useMutation(REMOVE_CUSTOMER_USER)
  const [createCustomer, { error: addError, reset: resetAdd }] = useMutation(CREATE_CUSTOMER, {
    errorPolicy: 'all',
  })

  const [addCustomerUser, { error: addUserError, reset: resetUserError }] = useMutation(ADD_USER_TO_CUSTOMER, {
    errorPolicy: 'all',
  })

  const { refetch } = useQuery(GET_CUSTOMERS, {
    variables: {
      page: page.current,
      limit: limit.current,
      dealership: currentDealership?.id,
    },
  })

  const { data: customerUsers, refetch: refetchCustomerUsers } = useQuery(GET_CUSTOMER_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      customerId: parseInt(selectedCustomer?.id),
      dealershipId: parseInt(currentDealership?.id),
    },
  })

  useEffect(() => {
    (async () => {
      getCustomerList()
    })()
  }, [])

  useEffect(() => {
    (async () => {
      getCustomerList()
    })()
  }, [
    currentDealership,
  ])

  const addCustomer = async () => {
    if (formErrors()) return
    setLoading(true)
    try {
      const res = await createCustomer({
        variables: {
          ...newCustomer,
          email: newCustomer.email,
          phone: newCustomer.phone,
          name: newCustomer.name,
          dealershipId: currentDealership.id,
          address: newCustomer.address,
        },
      })
      if (res.data?.createCustomer) {
        getCustomerList()
        closeModal()
      }
    } catch (err) {
      console.log('error creating customer:', err)
    } finally {
      setLoading(false)
    }
  }

  const handleRemoveCustomerUser = async () => {
    try {
      const res = await removeCustomerUser({
        variables: {
          userId: parseInt(selectedCustomerUser?.id),
          customerId: parseInt(selectedCustomer?.id),
          dealershipId: parseInt(currentDealership?.id),
        },
      })

      if (res?.data?.removeCustomerUser === 'Success') {
        refetchCustomerUsers()
        setSelectedCustomerUser(null)
        setShowConfirmModal(false)
      }
    } catch (err) {
      console.log('handleRemoveCustomerUser: ', err)
    }
  }

  const getErrorMessage = (type) => {
    if (type === 'add') {
      return addError.message
    }
  }

  const getCustomerList = async () => {
    try {
      const res = await refetch()

      if (res.data) {
        setCustomers(res.data.getCustomers)
      }
    } catch (err) {
      if (err && err.message.includes('Invalid credentials')) {
        return logout()
      }
    }
  }

  const setField = (field, value) => {
    const customerData = newCustomer
    customerData[field] = value
    setCustomerErrors({
      ...customerErrors,
      [field]: null,
    })
    setNewCustomer(customerData)
  }

  const formErrors = () => {
    setCustomerErrors(customerState)
    const errors = customerState
    let hasErrors = false
    for (const [key, value] of Object.entries(newCustomer)) {
      if (key === 'pid') continue
      if (!value || value.length < 1) {
        errors[key] = t('Please fill in the field')
        hasErrors = true
      }
    }
    setCustomerErrors(errors)
    return hasErrors
  }

  const closeModal = () => {
    resetAdd()
    setNewCustomer(customerState)
    setCustomerErrors(customerState)
    setModal(false)
  }

  const getPreviousPage = () => {
    page.current = page.current - 1
    refetch({
      page: page.current,
      limit: limit.current,
    })
  }

  const getNextPage = () => {
    page.current = page.current + 1
    refetch({
      page: page.current,
      limit: limit.current,
    })
  }

  const closeEditModal = () => {
    setEditCustomerModal(false)
    setSelectedCustomer(null)
  }

  const setUserField = (field, value) => {
    setCustomerUser({
      ...customerUser,
      [field]: value,
    })
  }

  const closeUserModal = () => {
    setUserModal(false)
    setCustomerUser(customerUserState)
    setSelectedCustomer(null)
    setAddUserSuccess(null)
    resetUserError()
  }

  const addUserToCustomer = async () => {
    if (userFormErrors() || !selectedCustomer) return

    try {
      const res = await addCustomerUser({
        variables: {
          userName: customerUser.name,
          userEmail: customerUser.email,
          userPhone: customerUser.phone,
          userPid: customerUser.pid,
          customerId: parseInt(selectedCustomer.id),
        },
      })

      if (res?.data?.addUserToCustomer === 'Success') {
        setAddUserSuccess(t('Customer user successfully added'))
        setCustomerUser(customerUserState)
      }
    } catch (err) {
      console.log('addUserToCustomer', err)
    }
  }

  const userFormErrors = () => {
    setCustomerUserErrors(customerUserState)
    const errors = customerUserState
    let hasErrors = false
    for (const [key, value] of Object.entries(customerUser)) {
      if (['name', 'phone', 'email', 'pid'].includes(key) && (!value || value.length < 1)) {
        errors[key] = t('Please fill in the field')
        hasErrors = true
      }
    }
    setCustomerUserErrors(errors)
    return hasErrors
  }

  const handleUsersOpen = (customerId) => {
    setSelectedCustomer(customerId)
    setUsersModal(true)
  }

  const closeUsersModal = () => {
    setSelectedCustomer(null)
    setUsersModal(false)
  }

  return (
    <>
      <div className="view customers-view">
        <div className="inner">
          <div className="view-title">
            <h1>{ t('Customers') }</h1>
            <button className="btn" onClick={() => openModal()}>
              { t('Add customer') }
            </button>
          </div>
          { customers && customers.rows && customers.rows.length > 0 ?
          <div className="view-table">
            <div className={ `view-table--content cols-3` } style={{
                gridTemplateColumns: `repeat(${tableHeaders.length}, minmax(100px, 1fr))`
              }}>
              { tableHeaders.map(x => 
                (
                  <div
                    key={x.label}
                    className={`view-table--header-item ${'view-table--header-item-' + x.key}`}
                  >
                    { x.label }
                  </div>
                ) 
              )}
              { customers.rows.map((x, index) => {
                  return tableHeaders.map((i, hIndex) => (
                    <div key={`${x.name}-${i.key}-${hIndex}-${index}`} className={`view-table--content-item ${'view-table--content-item-' + i.key} ${index % 2 === 1 ? 'odd' : 'even'}`}>
                        { i.key === 'actions' ?
                          <div>
                            <button className='btn btn-small' onClick={() => {
                              setSelectedCustomer(x)
                              setUserModal(true)
                            }}>
                              <AddUserIcon />
                            </button>
                            <button className='btn btn-small' onClick={() => handleUsersOpen(x)}>
                              <UsersIcon />
                            </button>
                            <button className='btn btn-small' onClick={() => {
                              setSelectedCustomer(x)
                              setEditCustomerModal(true)
                            }}>
                              <EditIcon />
                            </button>
                          </div>
                        :
                          <span>{ x[i.key] }</span>
                        }
                    </div>
                  ))}
              )}
            </div>
            <Pagination total={customers.count} onPrev={getPreviousPage} onNext={getNextPage} current={page.current} limit={limit.current} />
          </div>
          :
          <EmptyResult message={ t('No customers added yet') } />
          }
        </div>
        <Modal
          title={t('Add customer')}
          show={ modal }
          close={() => closeModal()}
        >
          <div className="users-view--add">
            <form className="users-view--add-form">
              <Input type={'text'} placeholder={ t('Sales order number') } label={ t('Sales order number') } onChange={(e) => setField('orderNr', e.target.value)} error={customerErrors.orderNr} value={newCustomer.orderNr} />
              <Input type={'text'} placeholder={ t('Customer ID') } label={ t('Customer ID') } onChange={(e) => setField('customerId', e.target.value)} error={customerErrors.customerId} value={newCustomer.customerId} />
              <Input type={'text'} placeholder={ t('Name') } label={ t('Name') } onChange={(e) => setField('name', e.target.value)} error={customerErrors.name} value={newCustomer.name} />
              <Input type={'text'} placeholder={ t('Phone') } label={ t('Phone') } onChange={(e) => setField('phone', e.target.value)} error={customerErrors.phone} value={newCustomer.phone} />
              <Input type={'email'} placeholder={ t('Email') } label={ t('Email') } onChange={(e) => setField('email', e.target.value)} error={customerErrors.email} value={newCustomer.email} />
              <Input type={'text'} placeholder={ t('Address') } label={ t('Address') } onChange={(e) => setField('address', e.target.value)} error={customerErrors.address} value={newCustomer.address} />
            </form>
            {
              addError &&
              <div className='add-error'>{ getErrorMessage('add') }</div>
            }
            <Button
              className="btn"
              label={ t('Add customer') }
              loading={loading}
              onClick={(e) => {
                e.preventDefault()
                addCustomer()
              }}
            />
          </div>
        </Modal>
        <Modal
          title={ t('Add user to customer') }
          show={ userModal }
          close={() => closeUserModal()}
        >
          <div className="users-view--add">
            <form className="users-view--add-form">
              <Input type={'text'} placeholder={ t('Name') } label={ t('Name') } onChange={(e) => setUserField('name', e.target.value)} error={customerUserErrors.name} value={customerUser.name} />
              <Input type={'text'} placeholder={ t('Phone') } label={ t('Phone') } onChange={(e) => setUserField('phone', e.target.value)} error={customerUserErrors.phone} value={customerUser.phone} />
              <Input type={'email'} placeholder={ t('Email') } label={ t('Email') } onChange={(e) => setUserField('email', e.target.value)} error={customerUserErrors.email} value={customerUser.email} />
              <Input type={'pid'} placeholder={ t('Personal Identification') } label={ t('Personal ID') } onChange={(e) => setUserField('pid', e.target.value)} error={customerUserErrors.pid} value={customerUser.pid} />
            </form>
            {
              addUserError &&
              <div className='add-error'>{ addUserError.message }</div>
            }
            {
              addUserSuccess &&
              <div className='add-success'>{ addUserSuccess }</div>
            }
            <Button
              className="btn"
              label={ t('Add user') }
              loading={loading}
              onClick={(e) => {
                e.preventDefault()
                addUserToCustomer()
              }}
            />
          </div>
        </Modal>
        <Modal
          title={ t('Customer users') }
          show={ usersModal }
          close={closeUsersModal}
        >
          <div className="users-list-view--add">
            { customerUsers?.getCustomerUsers?.length > 0 ?
              customerUsers.getCustomerUsers.map((x) =>
                <UsersListItem
                  key={x.id}
                  data={x}
                  onDelete={() => {
                    setSelectedCustomerUser(x)
                    setShowConfirmModal(true)
                  }}
                />
              )
              :
              t('There are no users under this customer')
            }
          </div>
        </Modal>
        <EditCustomer
          show={editCustomerModal}
          customerItem={selectedCustomer}
          close={closeEditModal}
          isCustomer={true}
          setField={(key, value) => {
            setSelectedCustomer({
              ...selectedCustomer,
              [key]: value,
            })
          }}
          onSuccess={() => {
            getCustomerList()
          }}
        />
      </div>
      <ConfirmModal
        show={showConfirmModal}
        confirmLabel={t('Remove')}
        cancelLabel={t('Cancel')}
        close={() => {
          setShowConfirmModal(false)
          setSelectedCustomerUser(null)
        }}
        onConfirm={handleRemoveCustomerUser}
        title={`${t('Remove')} ${selectedCustomerUser?.name}?`}
      />
    </>
  );
}

export default Customers;
