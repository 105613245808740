import React, { useState } from 'react';
import './Login.scss';
import { useAuth } from '../../hooks/useAuth'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'

const languages = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Eesti',
    value: 'et',
  },
]

const Login = () => {

  const { login } = useAuth()

  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [emailError, setEmailError] = useState(null)
  const [passError, setPassError] = useState(null)
  const [error, setError] = useState(false)

  const { t } = useTranslation()

  const validateFields = () => {
    clearErrors()
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(t('Invalid email address'))
      return false
    }
    if (!password) {
      setPassError(t('Insert password'))
      return false
    }
    return true
  }

  const clearErrors = () => {
    setEmailError(null)
    setPassError(null)
    setError(false)
  }

  const changeLanguage = (lang) => {
    i18next.changeLanguage(lang)
  }

  return (
    <>
      <div className="login-view">
        <div className="login-view--inner">
          <div className="login-view--logo"></div>
          <h3>{ t('Ordering environment') }</h3>
          <div className='dropdown language-switch'>
              <div className='dropdown-current'>{ languages.find(x => x.value === i18next.language)?.label }</div>
              <div className='dropdown-list'>
                <div className='dropdown-list-inner'>
                  { languages.map(x => <div key={`language-${x.value}`} className='dropdown-list-item' onClick={() => changeLanguage(x.value)}><span>{ x.label }</span></div>) }
                </div>
              </div>
          </div>
          <form>
            <div className="input-wrapper">
              <input type="email" placeholder={ t('Email') } onChange={(event) => setEmail(event.target.value)} />
              { emailError && <div className="input-wrapper--error">{ emailError }</div> }
            </div>
            <div className="input-wrapper">
              <input type="password" placeholder={ t('Password') } onChange={(event) => setPassword(event.target.value)} />
              { passError && <div className="input-wrapper--error">{ passError }</div> }
            </div>
            { error && <div className="login-error">{ t('Error logging in') }</div> }
            <button
              className="btn"
              onClick={async (e) => {
                e.preventDefault()
                if (!validateFields()) return
                const res = await login(email, password)
                if (!res) {
                  setError(true)
                }
              }}
            >
              { t('Log in') }
            </button>
            <Link to={'/password'}>{ t('Forgot password') }</Link>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
