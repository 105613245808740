import { useMutation, useQuery } from '@apollo/client'
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import { ADD_ORDER_ITEM, DELETE_ORDER, FIND_PRODUCT, REMOVE_ORDER_ITEM, SUBMIT_ORDER, UPDATE_ORDER_ITEM } from '../../graphql/mutations'
import { GET_ORDER } from '../../graphql/queries'
import './Order.scss'
import AddToOrder from '../../components/AddToOrder/AddToOrder'
import readXlsxFile from 'read-excel-file'
import Button from '../../components/Button/Button'
import { useAuth } from '../../hooks/useAuth'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { formatDate, formatPrice } from '../../helpers/format'
import DeliveryModal from '../../components/DeliveryModal'
import { DeliveryMethod } from '../../helpers/const'
import TableInput from '../../components/TableInput'

const Order = () => {

  const params = useParams()
  const { user, currentDealership, currentCustomer } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [searchInput, setSearchInput] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [loadingAdd, setLoadingAdd] = useState(false)
  const [loadingExcel, setLoadingExcel] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [submitError, setSubmitError] = useState(null)
  const [searchError, setSearchError] = useState(null)
  const [splitRows, setSplitRows] = useState(false)
  const [deliveryModal, setDeliveryModal] = useState(false)
  const selectedDealership = useRef(currentDealership)
  const fileInput = useRef()
  const searchRef = useRef()
  const [rows, setRows] = useState([])

  const { data, loading, refetch } = useQuery(GET_ORDER, {
    fetchPolicy: 'network-only',
    variables: {
      orderId: params && params.orderId ? parseInt(params.orderId) : null,
    },
    onCompleted: (data) => {
      const { getOrder } = data
      if (getOrder && getOrder.items && getOrder.items.length > 0) setRows(getOrder.items)
    },
  })

  const [findProduct] = useMutation(FIND_PRODUCT, {
    fetchPolicy: 'no-cache',
  })
  const [addOrderItem] = useMutation(ADD_ORDER_ITEM, {
    fetchPolicy: 'no-cache',
  })
  const [removeOrderItem] = useMutation(REMOVE_ORDER_ITEM, {
    fetchPolicy: 'no-cache',
  })
  const [updateOrderItem] = useMutation(UPDATE_ORDER_ITEM, {
    fetchPolicy: 'no-cache',
  })
  const [submitOrder] = useMutation(SUBMIT_ORDER)
  const [deleteOrder, { error: deleteError }] = useMutation(DELETE_ORDER)

  useEffect(() => {
    document.body.addEventListener('keyup', handleEsc)

    return () => {
      document.body.removeEventListener('keyup', handleEsc)
    }
  }, [])

  useEffect(() => {
    (async () => {
      if (params && params.orderId) {
        refetch({
          orderId: parseInt(params.orderId),
        })
      }
    })()
  }, [params])

  useEffect(() => {
    if (currentDealership !== selectedDealership.current) {
      navigate('/orders')
    }
  }, [currentDealership])

  const tableHeaders = [
    {
      label: t('Product ID'),
      key: 'sku',
    },
    {
      label: t('Description'),
      key: 'name',
    },
    {
      label: t('Brand'),
      key: 'brand',
    },
    {
      label: t('Quantity'),
      key: 'quantity',
    },
    {
      label: t('Stock'),
      key: 'stock',
    },
    {
      label: t('Unit price'),
      key: 'price',
    },
    {
      label: t('Discount'),
      key: 'discount',
    },
    {
      label: t('Sum'),
      key: 'sum',
    },
    {
      label: t('Sum with VAT'),
      key: 'vat',
    },
    {
      label: t('Comments'),
      key: 'description',
    },
    {
      label: t('Delivery date'),
      key: 'deliveryDate',
    },
    {
      label: t('Status'),
      key: 'status',
    },
  ]

  if (user && user.role === 'User' && data && data.getOrder && data.getOrder.status === 'Draft') {
    tableHeaders.push({
      label: '',
      key: 'remove',
    })
  }

  const search = async () => {
    setSearchError(null)
    setLoadingSearch(true)
    try {
      const res = await findProduct({
        variables: {
          prodId: searchInput,
          dealershipId: currentDealership.id,
          customerId: currentCustomer.id,
        },
      })
      if (res.data?.findProduct && res.data?.findProduct.length > 0) {
        setSearchResults(res.data.findProduct)
      } else {
        setSearchError(t('No results found'))
      }
    } catch (err) {
      console.log(err)
      setSearchError(t('No results found'))
    } finally {
      setLoadingSearch(false)
    }
  }

  const addToOrder = async (index, quantity, description, input = null) => {
    setLoadingAdd(true)
    try {
      const item = input ? input : searchResults[index]

      const res = await addOrderItem({
        variables: {
          item: {
            sku: item.sku,
            brand: item.brand,
            quantity: `${quantity}`,
            description: description,
          },
          orderId: parseInt(params.orderId),
          split: splitRows,
        },
      })
      if (res.data?.addOrderItem) {
        resetSearch()

        refetch({
          orderId: parseInt(params.orderId),
        })
        searchRef.current.focus()
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoadingAdd(false)
    }
  }

  const handleSend = () => {
    setDeliveryModal(true)
  }

  const sendOrder = async (deliveryMethod) => {
    setLoadingSubmit(true)
    try {
      const res = await submitOrder({
        variables: {
          orderId: parseInt(params.orderId),
          delivery: deliveryMethod,
        },
      })

      if (res.data?.submitOrder === 'SUCCESS') {
        setSubmitted(true)
      } else {
        setSubmitError(t('Error creating order'))
      }
    } catch (err) {
      console.log(err)
      setSubmitError(t('Error sending order'))
    } finally {
      setLoadingSubmit(false)
    }
  }

  const removeOrder = async () => {
    try {
      const res = await deleteOrder({
        variables: {
          orderId: parseInt(params.orderId),
        }
      })
      if (res.data?.deleteOrder === 'SUCCESS') {
        navigate('/')
      }
    } catch (err) {
      console.log('Error deleting order', err)
    }
  }

  const removeFromOrder = async (item) => {
    if (!item) return
    try {
      const res = await removeOrderItem({
        variables: {
          itemId: parseInt(item.id),
        },
      })

      if (res?.data?.removeOrderItem) {
        const newRows = rows.filter(x => x.id !== item.id)
        setRows(newRows)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const changeItemValue = async (itemId, key, value) => {
    if (!itemId || !key || !value) return

    const itemIndex = rows.findIndex(x => x.id === itemId)
    const newRows = rows
    newRows[itemIndex][key] = value
    setRows(newRows)

    try {
      await updateOrderItem({
        variables: {
          itemId,
          [key]: value,
        },
      })

    } catch (err) {
      console.log('Error updating row value:', err)
    }
  }

  const editingDisabled = () => {
    return !data || (data.getOrder && data.getOrder.status === 'Sent') || ['Superadmin', 'Admin'].includes(user.role)
  }

  const getColValue = (item, key) => {

    switch (key) {
      case 'price':
        return `${formatPrice(item.price)} EUR`
      case 'discount':
        return `${item?.discount ? item.discount : '0'}%`
      case 'sum':
        return `${formatPrice(item.customerPrice * item.quantity)} EUR`
      case 'vat':
        return `${formatPrice(item.vatPrice * item.quantity)} EUR`
      case 'remove':
        return <button className="btn btn-remove" onClick={() => removeFromOrder(item)}>{ t('Remove') }</button>
      case 'stock':
        return item.stock ? item.stock : 0
      case 'deliveryDate':
        return item.deliveryDate ? new Date(+item.deliveryDate).toLocaleDateString('et', {
          month: '2-digit',
          year: 'numeric',
          day: '2-digit',
        }) : ''
      case 'description':
        return editingDisabled() ?
          item?.description
          : <textarea maxLength={17} spellCheck={false} value={item?.description} onChange={(e) => changeItemValue(item.id, 'description', e.nativeEvent.target.value)} />
      case 'quantity':
        return <TableInput
          disabled={editingDisabled()}
          type={'number'}
          label={'quantity'}
          initialValue={item.quantity}
          fieldUpdated={(label, value) => changeItemValue(item.id, label, parseInt(value))}
        />
      case 'status':
        if (item.status === '1') return t('Accepted')
        if (item.status === '2') return t('Ordered')
        if (item.status === '3') return t('Invoiced')
        return t('Not sent')
    }
    return item[key]
  }

  const loadFromExcel = async () => {
    setLoadingExcel(true)
    if (!fileInput.current?.files) return
    try {
      const items = await readXlsxFile(fileInput.current.files[0])
      items.shift()

      await Promise.all(items.map(async (row) => {
        const item = {
          sku: `${row[0]}`,
          brand: `${row[1]}`,
          quantity: row[2],
          description: `${row[3] ? row[3] : ''}`,
        }
        await addToOrder(null, item.quantity, item.description ? item.description : '', item)
      }))
    } catch (err) {
      console.log('Error loading from excel', err)
    } finally {
      fileInput.current.value = null
      setLoadingExcel(false)
      refetch()
    }
  }

  const resetSearch = () => {
    setSearchInput('')
    setSearchResults([])
    searchRef.current.focus()
  }

  const getSum = (vat = false) => {
    let sum = 0

    if (rows && rows.length > 0) {
      sum = rows.reduce((acc, curr) => {
        if (vat) {
          acc += curr.vatPrice * curr.quantity
        }
        if (!vat) {
          acc += curr.customerPrice * curr.quantity
        }
        return acc
      }, 0)
    }
    return sum
  }

  const getOrderStatus = (status) => {
    switch (status) {
      case 'Draft':
        return t('Draft')
      case 'Invoiced':
        return t('Invoiced')
      case 'Sent':
        return t('Ordered')
    }
  }

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      search()
    }
  }

  const handleEsc = (e) => {
    if (e.key === 'Escape') {
      resetSearch()
    }
  }

  const getDeliveryLabel = (method) => {
    switch (method) {
      case DeliveryMethod.Courier:
        return t('Courier')
      case DeliveryMethod.Cup:
        return t('Porsche Cup')
      case DeliveryMethod.Dealership:
        return t('Dealership')
      default:
        return t('Not picked')
    }
  }

  if (submitted) return (
    <div className="view order-view">
      <div className="inner">
        <div className="view-success">
          <h3>{ t('Order has been sent') }</h3>
          <Link to={'/orders'}>{ t('View orders') }</Link>
        </div>
      </div>
    </div>
  )

  return (
    <div className="view order-view">
      <div className="inner">
        <div className="view-title">
          <div className='view-title--inner'>
            <h1>{ t('Order') }</h1>
            {
              loading &&
              <div className="loader loader-dark"></div>
            }
          </div>
          { data && data.getOrder &&
            <div>
              <p><span>{ `${t('Status')}:` }</span>{ ` ${getOrderStatus(data.getOrder.status)}` }</p>
              { 
                getOrderStatus(data.getOrder.status) === 'Sent' &&
                <>
                  <p className='sent-date'><span>{ t('Submitted on') } </span><span>{ formatDate(+data.getOrder.updatedAt) }</span></p> 
                  <p className='delivery-method'><span>{ `${t('Delivery method')}:` } </span><span>{ getDeliveryLabel(data?.getOrder?.deliveryMethod) }</span></p> 
                </>
              }
            </div>
          }
        </div>
        {
          data && data.getOrder && (
            <>
              { user && user.role === 'User' && data.getOrder.status === 'Draft' &&
                <div className="view-search">
                  <div className="view-search--input input-wrapper">
                    <input ref={searchRef} autoFocus={true} type="text" placeholder={ t('Insert product ID') } value={searchInput} onChange={(e) => setSearchInput(e.target.value)} onKeyUp={handleSearchKeyPress} />
                    { searchError && <div className='add-error'>{ searchError }</div> }
                  </div>
                  {
                    searchResults && searchResults.length > 0 &&
                    <div className="view-search--results">
                      <div className="view-search--results-list">
                        {
                          searchResults.map((x, i) =>
                            <div key={`${x.sku}-${x.brand}`} className="view-search--results-item">
                              <h4>{ `${x.name} (${x.sku})` }</h4>
                              <p>{ `${t('Brand')}: ${x.brand}` }</p>
                              <p>{ `${t('Price')}: ${formatPrice(x.price)} EUR` }</p>
                              <div className="view-search--results-item-footer">
                                <div className="view-search--results-item-stock">
                                  <div>{ `${t('Stock')}: ${x.stock}` }</div>
                                  { x.deliveryDate && <div>{ `${t('Delivery')}: ${x.deliveryDate}` }</div> }
                                </div>
                                <div className="view-search--results-item-actions">
                                  <AddToOrder item={i} add={addToOrder} loading={loadingAdd} focus={i === 0} />
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  }
                  <Button disabled={!searchInput || searchInput.length < 1} onClick={() => search()} loading={loadingSearch} label={ t('Search') } />
                  <div className="view-search--excel">
                    <label className={`btn btn-excel ${loadingExcel ? 'loading' : ''}`} htmlFor="excelInput">
                      {
                        loadingExcel ?
                        <div className="loader"></div>
                        :
                        <span>{ t('Load from excel file') }</span>
                      }
                    </label>
                    <input id="excelInput" ref={fileInput} type="file" onChange={loadFromExcel} />
                    <div className='view-search--excel-template'>
                      <a href={`${window.location.origin}/assets/order-template.xlsx`}>Get Excel template</a>
                    </div>
                  </div>
                  <div className='view-search--excel-split'>
                    <label htmlFor="split-rows">{ t('Split order rows') }</label>
                    <input id="split-rows" type={'checkbox'} onChange={() => setSplitRows(!splitRows)} />
                  </div>
                </div>
              }
              <div className='view-table--wrapper'>
              <table className="view-table">
                <thead>
                  <tr>
                  { tableHeaders.map(x => 
                    (
                      <th
                        key={x.label}
                        className={ `view-table--header-item view-table--header-item-${x.key} ${x.key === 'remove' ? 'remove' : ''}` }
                      >
                        { x.label }
                      </th>
                    ) 
                  )}
                  </tr>
                </thead>
                <tbody>
                  { rows && rows.map((x, index) => 
                    <tr key={`row-${index}`}>
                      {
                        tableHeaders.map((i, hIndex) => (
                          <td key={`${x.sku}-${index}-${hIndex}-${i.key}`} className={`view-table--content-item view-table--content-item-${i.key}${x.error ? ' view-table--content-item-error' : ''} ${index % 2 === 1 ? 'odd' : 'even'} ${i.key === 'remove' ? 'remove' : ''}`}>
                            <span>{ getColValue(x, i.key) }</span>
                          </td>
                        ))
                      }
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="view-table--footer">
                <div className="view-table--total">
                  <div className="view-table--sum">{ `${t('Sum')}: ${formatPrice(getSum())} EUR`}</div>
                  <div className="view-table--vat-sum">{ `${t('Sum with VAT')}: ${formatPrice(getSum(true))} EUR` }</div>
                </div>
                { user && user.role === 'User' && data && data.getOrder && data.getOrder.status === 'Draft' && 
                  <div className="view-table--actions">
                      <Button onClick={() => removeOrder()} loading={loadingDelete} label={ t('Discard order') } secondary={true} /> 
                      <Button disabled={data?.getOrder?.items?.length <= 0} onClick={() => handleSend()} loading={loadingSubmit} label={ t('Submit order') } />
                  </div>
                }
              </div>
              </div>
              {
                submitError || deleteError &&
                <div className="view-error">
                  <span>{ submitError || deleteError.message }</span>
                </div>
              }
            </>
          )
        }
      </div>
      {
        searchResults && searchResults.length > 0 &&
        <div className="search-overlay" onClick={() => resetSearch()}></div>
      }
      <DeliveryModal
        show={ deliveryModal }
        close={() => {
            setDeliveryModal(false)
        }}
        onConfirm={(method) => {
          sendOrder(method)
          setDeliveryModal(false)
        }}
      />
    </div>
  )
}

export default Order