import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useAuth } from '../../hooks/useAuth';
import './Header.scss';

const Header = () => {

  const { pathname } = useLocation()
  const { logout, user, setCurrentDealership, currentDealership, currentCustomer, setCurrentCustomer } = useAuth()
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()

  const { t } = useTranslation()

  useEffect(() => {
    closeMenu()
  }, [pathname])

  const getActiveClass = (paths) => {
    return paths.some(x => x === pathname) ? 'active' : ''
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  const changeDealership = (dealership) => {
    setCurrentDealership(dealership)
    closeMenu()
  }

  const changeCustomer = (customer) => {
    setCurrentCustomer(customer)
    closeMenu()
  }

  return (
    <header className={menuOpen ? 'opened' : ''}>
      <div className="inner">
        <div className="logo" onClick={() => navigate('/')}></div>
        <nav>
          <div className='navigation'>
            <Link className={getActiveClass(['/orders', '/'])} to="/orders">{ t('Orders') }</Link>
            { user && user.role === 'User' ? <Link className={getActiveClass(['/invoices'])} to="/invoices">{ t('Invoices') }</Link> : <></> }
            {
              user
              && <Link className={getActiveClass(['/users'])} to="/users">{ t('Users') }</Link>
            }
            {
              user && ['Admin'].includes(user.role)
              && <Link className={getActiveClass(['/customers'])} to="/customers">{ t('Customers') }</Link>
            }
            {
              user && 'Superadmin' === user.role
              && <Link className={getActiveClass(['/dealerships'])} to="/dealerships">{ t('Dealerships') }</Link>
            }
          </div>
          <div className='actions'>
            { user && user.role !== 'Superadmin' && (user.dealerships && currentDealership || user.customers && currentCustomer) &&
              <>
                <div className='dropdown'>
                  {
                    user.role === 'Admin' ?
                    <>
                    <div className='dropdown-current'>{ currentDealership.name }</div>
                    { user.dealerships && user.dealerships.length > 1 &&
                      <div className='dropdown-list'>
                        <div className='dropdown-list-inner'>
                          { user.dealerships.map(x => <div key={x.id} className='dropdown-list-item' onClick={() => changeDealership(x)}><span>{ x.name }</span></div>) }
                        </div>
                      </div>
                    }
                    </>
                    :
                    <>
                    <div className='dropdown-current'>{ currentCustomer.name }</div>
                    { user.customers && user.customers.length > 1 &&
                      <div className='dropdown-list'>
                        <div className='dropdown-list-inner'>
                          { user.customers.map(x => <div key={x.id} className='dropdown-list-item' onClick={() => changeCustomer(x)}><span>{ x.name }</span></div>) }
                        </div>
                      </div>
                    }
                    </>
                  }
                </div>
                {
                  user.role === 'User' && user.customers && currentCustomer && user.customers.some(x => x.id === currentCustomer.id) &&
                  <div className='dropdown'>
                      <div className='dropdown-current'>{ currentDealership.name }</div>
                      <div className='dropdown-list'>
                        <div className='dropdown-list-inner'>
                          { user.customers.find(x => x.id === currentCustomer.id).Dealerships.map(x => <div key={x.id} className='dropdown-list-item' onClick={() => changeDealership(x)}><span>{ x.name }</span></div>) }
                        </div>
                      </div>
                  </div>
                }
              </>
            }
            <div className='identity'>
              { user.name }
            </div>
            <button className="btn btn-logout" onClick={() => {
              navigate('/')
              logout()
            }}>{ t('Log out') }</button>
          </div>
        </nav>
        <div className="menu-btn" onClick={toggleMenu}>
          <div></div>
        </div>
      </div>
    </header>
  );
}

export default Header;
