import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import './Dealerships.scss';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_DEALERSHIP, CREATE_USER, EDIT_DEALERSHIP } from '../../graphql/mutations';
import { GET_DEALERSHIPS, GET_DEALERSHIP_USERS } from '../../graphql/queries';
import EmptyResult from '../../components/EmptyResult/EmptyResult';
import Input from '../../components/Input/Input';
import { useAuth } from '../../hooks/useAuth';
import Pagination from '../../components/Pagination/Pagination';
import Button from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import AddUserIcon from '../../components/Icons/add-user';
import EditIcon from '../../components/Icons/edit';
import UsersIcon from '../../components/Icons/users';
import EditUser from '../../components/EditUser/EditUser';
import { useNavigate } from 'react-router';
import UsersListItem from '../../components/UsersListItem';

const Dealerships = () => {

  const { t } = useTranslation()

  const dealershipState = {
    name: '',
    email: '',
    brandId: '',
    companyId: '',
    phone: '',
  }

  const dealershipUserState = {
    userEmail: '',
    userPhone: '',
    userName: '',
    userPid: '',
  }

  const tableHeaders = [
    {
      label: t('Dealership'),
      key: 'name',
    },
    {
      label: t('Company ID'),
      key: 'companyId',
    },
    {
      label: t('Brands'),
      key: 'brands',
    },
    {
      label: t('Phone'),
      key: 'phone',
    },
    {
      label: t('Email'),
      key: 'email',
    },
    {
      label: t('Actions'),
      key: 'actions',
    },
  ]

  const { logout, user } = useAuth()
  const navigate = useNavigate()

  const [modal, setModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [editUserModal, setEditUserModal] = useState(false)
  const [usersModal, setUsersModal] = useState(false)
  const [userModal, setUserModal] = useState(false)

  const [dealership, setDealership] = useState(dealershipState)
  const [dealershipUser, setDealershipUser] = useState(dealershipUserState)
  const [editDealershipUser, setEditDealershipUser] = useState(dealershipUserState)

  const [dealershipUserErrors, setDealershipUserErrors] = useState(dealershipUserState)
  const [dealershipErrors, setDealershipErrors] = useState(dealershipState)
  const [addError, setAddError] = useState(dealershipState)

  const [addUserSuccess, setAddUserSuccess] = useState(null)

  const [selectedDealership, setSelectedDealership] = useState(null)
  const [dealerships, setDealerships] = useState([])
  const [dealershipUsers, setDealershipUsers] = useState([])

  const [loading, setLoading] = useState(false)

  const page = useRef(0)
  const limit = useRef(10)

  const openModal = () => setModal(true);

  const [createDealership, { error: addDealershipError, reset: resetDealershipState }] = useMutation(CREATE_DEALERSHIP, {
    errorPolicy: 'all',
  })
  const [editDealership, { error: editDealershipError }] = useMutation(EDIT_DEALERSHIP)
  const [createUser, { error: addUserError, reset: resetDealershipUserState }] = useMutation(CREATE_USER, {
    errorPolicy: 'all',
  })

  const { refetch: getDealershipUsers } = useQuery(GET_DEALERSHIP_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      page: page.current,
      limit: limit.current,
      dealership: selectedDealership ? selectedDealership.id : 0,
    },
  })
  const { refetch: getDealerships } = useQuery(GET_DEALERSHIPS, {
    fetchPolicy: 'no-cache',
    variables: {
      page: page.current,
      limit: limit.current,
    },
  })

  useEffect(() => {
    (async () => {
      if (!['Superadmin'].includes(user.role)) {
        navigate('/')
      }
      getDealershipList()
    })()
  }, [])

  useEffect(() => {
    (async () => {
      if (usersModal && selectedDealership) {
        const res = await getDealershipUsers({
          page: page.current,
          limit: limit.current,
          dealership: selectedDealership.id,
        })
        if (res.data?.getDealershipUsers) {
          setDealershipUsers(res.data?.getDealershipUsers?.rows)
        }
      }
    })()
  }, [
    usersModal,
    selectedDealership,
  ])

  const addDealership = async () => {
    if (formErrors()) return
    setLoading(true)

    try {
      const res = await createDealership({
        variables: {
          ...dealership,
          companyId: parseInt(dealership.companyId),
        },
      })
      if (res.data?.createDealership) {
        getDealershipList()
        closeModal()
      }
    } catch (err) {
      if (err.message.includes('USER_EXISTS')) {
        setAddError(t('User with email or personal id already exists'))
      } else {
        setAddError(t('Error adding dealership'))
      }
      console.log('error creating dealership', err)
    } finally {
      setLoading(false)
    }
  }

  const updateDealership = async () => {
    if (formErrors()) return
    setLoading(true)
    try {
      const res = await editDealership({
        variables: {
          dealershipId: selectedDealership.id,
          name: dealership.name,
          phone: dealership.phone,
          email: dealership.email,
          brandId: Array.isArray(dealership.brandId) ? dealership.brandId.join(',') : dealership.brandId,
        },
      })
      if (res.data?.editDealership) {
        getDealershipList()
        closeEditModal()
      }
    } catch (err) {
      console.log('error updating dealership', err)
    } finally {
      setLoading(false)
    }
  }

  const removeDealership = async () => {
    setLoading(true)
    try {
      const res = await editDealership({
        variables: {
          dealershipId: selectedDealership.id,
          remove: true,
        },
      })
      if (res.data?.editDealership) {
        getDealershipList()
        closeEditModal()
      }
    } catch (err) {
      console.log('error updating dealership', err)
    } finally {
      setLoading(false)
    }
  }

  const addDealershipUser = async () => {
    if (userFormErrors() || !selectedDealership) return
    setLoading(true)
    try {
      const res = await createUser({
        variables: {
          dealership: selectedDealership.id,
          name: dealershipUser.userName,
          phone: dealershipUser.userPhone,
          email: dealershipUser.userEmail,
          pid: dealershipUser.userPid,
        },
      })
      if (res.data?.createUser) {
        getDealershipList()
        setDealershipUser(dealershipUserState)
        setAddUserSuccess(t('Dealership user successfully added'))
      }
    } catch (err) {
      console.log('error creating dealership user', err.message)
    } finally {
      setLoading(false)
    }
  }

  const getDealershipList = async () => {
    try {
      const res = await getDealerships()

      if (res.data) {
        setDealerships(res.data.getDealerships)
      }
    } catch (err) {
      if (err && err.message.includes('Invalid credentials')) {
        return logout()
      }
    }
  }

  const setField = (field, value) => {
    const newDealership = dealership
    newDealership[field] = value
    resetDealershipState()
    setDealershipErrors({
      ...dealershipErrors,
      [field]: null,
    })
    setDealership(newDealership)
    return
  }

  const setUserField = (field, value) => {
    const newUser = dealershipUser
    newUser[field] = value
    resetDealershipState()
    setDealershipUserErrors({
      ...dealershipUserErrors,
      [field]: null,
    })
    setDealershipUser(newUser)
    return
  }

  const formErrors = () => {
    setAddError(null)
    setDealershipErrors(dealershipState)
    const errors = dealershipState
    let hasErrors = false
    for (const [key, value] of Object.entries(dealership)) {
      if (!value || value.length < 1) {
        errors[key] = t('Please fill in the field')
        hasErrors = true
      }
    }
    setDealershipErrors(errors)
    return hasErrors
  }

  const userFormErrors = () => {
    setAddError(null)
    setDealershipUserErrors(dealershipUserState)
    const errors = dealershipUserState
    let hasErrors = false
    for (const [key, value] of Object.entries(dealershipUser)) {
      if (['userName', 'userPhone', 'userEmail', 'userPid'].includes(key) && (!value || value.length < 1)) {
        errors[key] = t('Please fill in the field')
        hasErrors = true
      }
    }
    setDealershipUserErrors(errors)
    return hasErrors
  }

  const closeModal = () => {
    setDealershipErrors(dealershipState)
    setDealership(dealershipState)
    setModal(false)
  }

  const closeEditModal = () => {
    setEditModal(false)
    setSelectedDealership(null)
    setDealership(dealershipState)
    setDealershipErrors(dealershipState)
  }

  const closeUserModal = () => {
    setUserModal(false)
    setDealershipUser(dealershipUserState)
    setDealershipUserErrors(dealershipState)
    resetDealershipUserState()
    setAddUserSuccess(null)
  }

  const getPreviousPage = async () => {
    page.current = page.current - 1
    const res = await getDealerships({
      page: page.current,
      limit: limit.current,
    })
    if (res && res.data && res.data.getDealerships) {
      setDealerships(res.data.getDealerships)
    }
  }

  const getNextPage = async () => {
    page.current = page.current + 1
    const res = await getDealerships({
      page: page.current,
      limit: limit.current,
    })
    if (res && res.data && res.data.getDealerships) {
      setDealerships(res.data.getDealerships)
    }
  }

  const closeEditUserModal = () => {
    setEditUserModal(false)
    setEditDealershipUser(null)
  }

  const openEditUserModal = (userItem) => {
    setUsersModal(false)
    setEditUserModal(true)
    setEditDealershipUser(userItem)
  }

  return (
    <>
      <div className="view dealerships-view">
        <div className="inner">
          <div className="view-title">
            <h1>{ t('Dealerships') }</h1>
            <button className="btn" onClick={() => openModal()}>
              { t('Add dealership') }
            </button>
          </div>
          { dealerships && dealerships.rows && dealerships.rows.length > 0 ?
          <div className="view-table">
            <div className={ `view-table--content` } style={{
                gridTemplateColumns: `repeat(${tableHeaders.length}, minmax(100px, 1fr))`
              }}>
              { tableHeaders.map(x => 
                (
                  <div
                    key={x.label}
                    className={`view-table--header-item ${'view-table--header-item-' + x.key}`}
                  >
                    { x.label }
                  </div>
                ) 
              )}
              { dealerships.rows.map((x, index) => {
                  return tableHeaders.map((i, hIndex) => (
                    <div key={`${x.name}-${i.key}-${hIndex}-${index}`} className={`view-table--content-item ${'view-table--content-item-' + i.key} ${index % 2 === 1 ? 'odd' : 'even'}`}>
                      { i.key === 'actions' ?
                        <div>
                          <button className='btn btn-small' onClick={() => {
                            setSelectedDealership(x)
                            setDealership({
                              ...x,
                              brandId: x.brands,
                              phone: x.phone ? x.phone : undefined
                            })
                            setEditModal(true)
                          }}>
                            <EditIcon />
                          </button>
                          <button className='btn btn-small' onClick={() => {
                            setSelectedDealership(x)
                            setUserModal(true)
                          }}>
                            <AddUserIcon />
                          </button>
                          <button className='btn btn-small' onClick={() => {
                            setSelectedDealership(x)
                            setUsersModal(true)
                          }}>
                            <UsersIcon />
                          </button>
                        </div>
                        :
                        <span>
                          { x[i.key] }
                        </span>
                      }
                    </div>
                  ))}
              )}
            </div>
            <Pagination total={dealerships.count} onPrev={getPreviousPage} onNext={getNextPage} current={page.current} limit={limit.current} />
          </div>
          :
          <EmptyResult message={ t('No dealerships added yet') } />
          }
        </div>
        <Modal
          title={ t('Add dealership') }
          show={ modal }
          close={() => closeModal()}
        >
          <div className="users-view--add">
            <form className="users-view--add-form">
              <Input type={'number'} placeholder={ t('Dealership ID') } onChange={(e) => setField('companyId', e.target.value)} error={dealershipErrors.companyId} value={dealership.companyId} />
              <Input type={'text'} placeholder={ t('Brand IDs (comma separated)') } onChange={(e) => setField('brandId', e.target.value)} error={dealershipErrors.brandId} value={dealership.brandId} />
              <Input type={'text'} placeholder={ t('Dealership name') } onChange={(e) => setField('name', e.target.value)} error={dealershipErrors.name} value={dealership.name} />
              <Input type={'email'} placeholder={ t('Dealership email') } onChange={(e) => setField('email', e.target.value)} error={dealershipErrors.email} value={dealership.email} />
              <Input type={'text'} placeholder={ t('Dealership phone') } onChange={(e) => setField('phone', e.target.value)} error={dealershipErrors.phone} value={dealership.phone} />
            </form>
            {
              addDealershipError &&
              <div className='add-error'>{ addDealershipError.message }</div>
            }
            <Button
              className="btn"
              label={ t('Add dealership') }
              loading={loading}
              onClick={(e) => {
                e.preventDefault()
                addDealership()
              }}
            />
          </div>
        </Modal>
        <Modal
          title={ t('Edit dealership') }
          show={ editModal }
          close={() => closeEditModal()}
        >
          <div className="users-view--add">
            <form className="users-view--add-form">
              <Input type={'text'} placeholder={ t('Dealership name') } onChange={(e) => setField('name', e.target.value)} error={dealershipErrors.name} value={dealership.name} />
              <Input type={'email'} placeholder={ t('Dealership email') } onChange={(e) => setField('email', e.target.value)} error={dealershipErrors.email} value={dealership.email} />
              <Input type={'text'} placeholder={ t('Dealership phone') } onChange={(e) => setField('phone', e.target.value)} error={dealershipErrors.phone} value={dealership.phone} />
              <Input type={'text'} placeholder={ t('Brand IDs (comma separated)') } onChange={(e) => setField('brandId', e.target.value)} error={dealershipErrors.brandId} value={dealership.brandId} />
            </form>
            {
              editDealershipError &&
              <div className='add-error'>{ editDealershipError.message='' }</div>
            }
            <div className='actions'>
              <Button
                className="btn"
                label={ t('Delete') }
                loading={loading}
                onClick={(e) => {
                  e.preventDefault()
                  removeDealership()
                }}
              />
              <Button
                className="btn"
                label={ t('Save') }
                loading={loading}
                onClick={(e) => {
                  e.preventDefault()
                  updateDealership()
                }}
              />
            </div>
          </div>
        </Modal>
        <Modal
          title={ t('Add dealership user') }
          show={ userModal }
          close={() => closeUserModal()}
        >
          <div className="users-view--add">
            <form className="users-view--add-form">
              <Input type={'text'} placeholder={ t('Name') } onChange={(e) => setUserField('userName', e.target.value)} error={dealershipUserErrors.userName} value={dealershipUser.userName} />
              <Input type={'text'} placeholder={ t('Phone') } onChange={(e) => setUserField('userPhone', e.target.value)} error={dealershipUserErrors.userPhone} value={dealershipUser.userPhone} />
              <Input type={'email'} placeholder={ t('Email') } onChange={(e) => setUserField('userEmail', e.target.value)} error={dealershipUserErrors.userEmail} value={dealershipUser.userEmail} />
              <Input type={'number'} placeholder={ t('Personal identificaion number') } onChange={(e) => setUserField('userPid', e.target.value)} error={dealershipUserErrors.userPid} value={dealershipUser.userPid} />
            </form>
            {
              addUserError &&
              <div className='add-error'>{ addUserError.message }</div>
            }
            {
              addUserSuccess &&
              <div className='add-success'>{ addUserSuccess }</div>
            }
            <Button
              className="btn"
              label={ t('Add user') }
              loading={loading}
              onClick={(e) => {
                e.preventDefault()
                addDealershipUser()
              }}
            />
          </div>
        </Modal>
        <Modal
          title={ t('Dealership users') }
          show={ usersModal }
          close={() => {
            setUsersModal(false)
          }}
        >
          <div className="users-list-view--add">
            { dealershipUsers && dealershipUsers.length > 0 ?
              dealershipUsers.map((x) =>
              <UsersListItem
                key={x.id}
                data={x}
                onEdit={() => openEditUserModal(x)}
              />
              )
              :
              t('There are no users under this dealership')
            }
          </div>
        </Modal>
        <EditUser
          close={closeEditUserModal}
          show={editUserModal}
          userItem={editDealershipUser}
          setField={(key, value) => {
            setEditDealershipUser({
              ...editDealershipUser,
              [key]: value,
            })
          }}
          dealership={selectedDealership}
          title={t('Update dealership user')}
          removeText={t('Remove from dealership')}
        />
      </div>
    </>
  );
}

export default Dealerships;
